<template>
	<div>
		<div class="container darkBack">
			<div class="font20 whiteFont">体育指导员</div>
			<!-- 选择器 -->
			<!-- <div>
				<ul class="lightBlueFont">
					<li style="position:relative">
						<div style="display:flex" @click="countiesopen">
							<div>{{city}}</div>
							<div class="triangle"></div>
						</div>
						<ul class="counties" v-show="counties">   
							<li class="countiesli" v-for="(item,index) in cityselect" :key="index" @click="countiesclick">{{item}}</li>
						</ul>
					</li>
					<li style="position:relative">
						<div style="display:flex">
							<div>{{project}}</div>
							<div class="triangle"></div>
						</div>
					</li>
					<li style="position:relative">
						<div style="display:flex" @click="gendershow1">
							<div>{{sex}}</div>
							<div class="triangle"></div>
						</div>
						<ul class="counties" v-show="gendershow">   
							<li class="countiesli" v-for="(item,index) in gender" :key="index" @click="genderhandle">{{item}}</li>
						</ul>
					</li>
					<li style="position:relative">
						<div style="display:flex">
							<div>{{level}}</div>
							<div class="triangle"></div>
						</div>
					</li>
				</ul>
			</div> -->
			<!-- <div>
				<ul class="lightBlueFont">
					<li style="position:relative" v-for="(item,index) in select" :key="index" @click="countiesopen">
						<div style="display:flex">
							<div>{{item}}</div>
							<div class="triangle"></div>
						</div>
						<ul class="counties" v-show="counties">   
							<li class="countiesli" v-for="(item,index) in district" :key="index" @click="countiesclick" :title=item>{{item}}</li>
						</ul>
					</li>
				</ul>
			</div> -->
			<!-- 指导员列表 -->
			<vueSeamlessScroll :data="sportList" class="seamlessWarp" :class-option="classOption">
				<div class="sportList">
					<!-- 单个运动员 -->
					<div class="sportItem" v-for="(item,index) in sportList" :key="index">
						<div class="sportInfo">
							<div class="blueFont font20">
								{{item.name}}
								<img class="sexIcon" :src="item.img">
							</div>
							<span class="lightGrayFont">履职 {{item.num}} 次</span>
						</div>
						<div class="tags">
							<div class="tag" v-for="(tag,num) in item.tags" :key="num">{{tag}}</div>
						</div>
					</div>
				</div>
			</vueSeamlessScroll>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";

	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		components:{
			vueSeamlessScroll
		},
		data() {
			return{
				city:'绍兴市',
				project:"项目",
				sex:"性别",
				level:"级别",

				sportList:[
					// {
					// 	name:"李兴辉",
					// 	img:require('../../../assets/images/manIcon.png'),
					// 	num:2329,
					// 	tags:['羽毛球','空手道','广场舞','太极','攀岩']
					// },{
					// 	name:"蒋亚江",
					// 	img:require('../../../assets/images/womanIcon.png'),
					// 	num:1329,
					// 	tags:['太极','田径']
					// },{
					// 	name:"王晓荣",
					// 	img:require('../../../assets/images/manIcon.png'),
					// 	num:929,
					// 	tags:['瑜伽']
					// },{
					// 	name:"马诺亚",
					// 	img:require('../../../assets/images/manIcon.png'),
					// 	num:829,
					// 	tags:['广场舞','摔跤','举重','蹦床']
					// },{
					// 	name:"马骁雨",
					// 	img:require('../../../assets/images/womanIcon.png'),
					// 	num:729,
					// 	tags:['羽毛球','射击','赛艇']
					// },{ 
					// 	name:"蒋景波",
					// 	img:require('../../../assets/images/manIcon.png'),
					// 	num:682,
					// 	tags:['划水','足球','篮球','排球']
					// },{
					// 	name:"荀永灏",
					// 	img:require('../../../assets/images/womanIcon.png'),
					// 	num:611,
					// 	tags:['手球']
					// },{
					// 	name:"薛喆烨",
					// 	img:require('../../../assets/images/womanIcon.png'),
					// 	num:578,
					// 	tags:['垒球','燃脂','台球']
					// },{
					// 	name:"赵锐涵",
					// 	img:require('../../../assets/images/womanIcon.png'),
					// 	num:472,
					// 	tags:['冰壶','围棋']
					// },{
					// 	name:"薛喆烨",
					// 	img:require('../../../assets/images/womanIcon.png'),
					// 	num:321,
					// 	tags:['垒球','燃脂','台球']
					// }
				],
				
				counties:false,    // 绍兴市下面的六个区下拉

				cityselect: [ "越城区", "柯桥区", "上虞区", "诸暨市", "嵊州市", "新昌县"],

				gendershow:false,
				gender:["男", "女"],
			}
		},
		methods:{
			 // 下拉箭头选择显示隐藏
			countiesopen(){
				if(this.counties === false){
					this.counties = true;
				}else{
					this.counties = false;  
				}
			},
			countiesclick(e){
				this.city = e.target.innerText
				this.counties = false;
			},

			gendershow1(){
				if(this.gendershow === false){
					this.gendershow = true;
				}else{
					this.gendershow = false;  
				}
			},
			genderhandle(e){
				this.sex = e.target.innerText
				this.gendershow = false;
			},
			// 获取指导员数据
			getInstructor(){
				let that = this;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getHomeSocialSportsInstructor",
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						that.sportList = [];
						res.data.data_list.data.forEach((item,index)=>{
							let tag = [];
							item.sportsitem_arr.forEach((son)=>{
								tag.push(son.title)
							})
							that.sportList.push({
								name:item.realname,
								img:item.sex == '男' ? require('../../../assets/images/manIcon.png') 
													: require('../../../assets/images/womanIcon.png'),
								num:item.resumption_cishu,
								tags:tag
							})
						})
						// console.log(that.sportList)
					},
				})
			}
		},
		computed: {
		    classOption () {
		       return {
		        step: 0.4, // 数值越大速度滚动越快
		        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
		        hoverStop: true, // 是否开启鼠标悬停stop
		        direction: 1, // 0向下 1向上 2向左 3向右
		        openWatch: true, // 开启数据实时监控刷新dom
		        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
		        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
		        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
		      }
		    }
		},
		mounted() {
			this.getInstructor();
		}
	}
</script>

<style scoped>
	.container{
		width: 5.96rem;
		height: 8.56rem;
		position: relative;
		padding: 0.2rem;
	}
	ul{
		margin-top: 0.3rem;
		width: 80%;
		display: flex;
		justify-content: space-around;
	}
	ul > li {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.triangle{
		width: 0rem;
		height: 0rem;
		border-top: 0.11rem solid #7EA7E2;
		border-left: 0.11rem solid transparent;
		border-right: 0.11rem solid transparent;
		margin: 0.05rem 0rem 0rem 0.1rem;
		cursor:pointer;
	}
	.sportList{
		margin-top: 0.4rem;
	}
	.sportItem{
		width: 80%;
		margin-left: 10%;
		margin-top: 0.2rem;
	}
	.sportInfo{
		display: flex;
		justify-content: space-between;
	}
	.sexIcon{
		width: 0.17rem;
		height: 0.17rem;
		margin-left: 0.1rem;
	}
	.tags{
		margin-top: 0.1rem;
		display: flex;
		flex-wrap: wrap;
	}
	.tag{
		font-size: 0.11rem;
		color: #FBAD57;
		padding: 0.03rem 0.06rem;
		height: 0.19rem;
		line-height: 0.20rem;
		text-align: center;
		border: 0.01rem solid #FBAD57;
		border-radius: 0.05rem;
		margin-right: 0.1rem;
		margin-bottom: 0.1rem;
	}
	.seamlessWarp{
		height: 7.2rem;
		overflow: hidden;
		margin-top: 0.1rem;
	}

	.lightBlueFont{
		position: relative;
		margin-left: 0.3rem;
	}
	

	.counties{
		width:1.0rem;
		display: inline-block;
		position: absolute;
		top:0rem;
		left:-0.1rem;
		color:#F1F1F1;
		font-size: 0.19rem;
		text-align: center;
		background:#000000B3;
		padding:0.06rem 0rem;
		box-sizing: border-box;
		max-height:4.1rem;
		overflow-x: hidden;
		overflow-y: scroll;
		z-index: 999;
	}
	.counties::-webkit-scrollbar {
			display: none;
		}
	.countiesli{
		width:1.00rem;
		line-height: 0.40rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding:0rem 0.1rem;
		box-sizing: border-box;
	}
	.countiesli:hover{
		color:#20D7FE;
		cursor: pointer;
	}
</style>